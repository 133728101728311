/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-04 15:11:43
 * @LastEditors: pansp
 * @LastEditTime: 2025-01-16 10:46:42
 */
import { service } from '@/utils/http'
import { paypalUrl } from '@/config'
import Axios from 'axios'

/**
 * 分页查询视频列表
 * @param data
 * @returns
 */
export function pageVideoList(data: any): Res<any> {
  return service.post('/h5/video/pageVideoList', data)
}

/**
 * 分页查询铃声列表
 * @param data
 * @returns
 */
export function pageRingList(data: any): Res<any> {
  return service.post('/h5/ring/pageRingList', data)
}

/**
 * 分页查询壁纸列表
 * @param data
 * @returns
 */
export function pageWallpaperList(data: any): Res<any> {
  return service.post('/h5/wallpaper/pageWallpaperList', data)
}

/**
 *  根据id集合获取铃声详情集合
 * @param data
 * @returns
 */
export function getRingDetails(params: any): Res<any> {
  return service.get('/h5/ring/getRingDetails', { params })
}

/**
 * 根据id集合获取视频详情集合
 * @param data
 * @returns
 */
export function getVideoDetails(params: any): Res<any> {
  return service.get('/h5/video/getVideoDetails', { params })
}

/**
 * 根据id集合获取壁纸详情集合
 * @param params
 * @returns
 */
export function getWallpaperDetails(params: any): Res<any> {
  return service.get('/h5/wallpaper/getWallpaperDetails', { params })
}

/**
 * 获取分类列表
 * @param params
 * @returns
 */
export function listCategories(params: { type: number }): Res<any> {
  return service.get('/h5/category/listCategories', { params })
}

/**
 * 获取歌单详情
 * @param params
 * @returns
 */
export function getPlayListDetails(params: { id: any }): Res<any> {
  return service.get('/h5/playList/getPlayListDetails', { params })
}

/**
 * 分页查询歌单下铃声列表
 * @param params
 * @returns
 */
export function pageRingsByPlaylistId(data: {
  id: any
  pageNum: number
  pageSize: number
}): Res<any> {
  return service.post('/h5/playList/pageRingsByPlaylistId', data)
}

/**
 * 获取指定群信息
 * @param params
 * @returns
 */
export function getGroupInfo(params: {
  groupId: any
  inviterUserId: any
  groupName: any
  inviterName: any
}): Res<any> {
  return service.get('/h5/im/getGroupInfo', { params })
}

/**
 * 邀请方式加入群聊
 * @param data
 * @returns
 */
export function addGroupInvitation(data: {
  groupId: any
  inviterUserId: any
  invitedName: string
  invitedMobile: string
  language: any
}): Res<any> {
  return service.post('/h5/im/addGroupMemberByInvitation', data)
}

/**
 * 检查用户是否注册Allo
 * @param params
 * @returns
 */
export function checkRegister(params: { mobile: string }): Res<any> {
  return service.get('/h5/user/checkRegister', { params })
}

/**
 * 根据用户Id获取用户信息
 * @param params
 * @returns
 */
export function getUserInfoByUserId(params: { userId: any }): Res<any> {
  return service.get('/h5/user/getUserInfoByUserId', { params })
}

/**
 * 认证信息提交
 * @param data
 * @returns
 */
export function submitAuthentication(data: {
  name: string
  pictureList: any
  type: any
}): Res<any> {
  return service.post('/h5/user/submitAuthentication', data)
}

/**
 * 用户实名认证
 * @param data
 * @returns
 */
export function validateIdCard(data: {
  name: string
  idCard: string
  cardImage: string
}): Res<any> {
  return service.post('/h5/user/validateIdCard', data)
}

/**
 * 获取用户当前提交的认证信息
 * @param params
 * @returns
 */
export function getCurrentAuthentication(params: { type: number }): Res<any> {
  return service.get('/h5/user/getCurrentAuthentication', { params })
}

/**
 *  获取腾讯云临时Cos授权信息
 * @param params
 * @returns
 */
export function getOssTmpAuthInfo() {
  return service.get('/h5/config/getOssTmpAuthInfo')
}

/**
 *  获取个人信息收集清单
 * @param params
 * @returns
 */
export function getLoginUserInfo() {
  return service.get('/h5/user/getLoginUserInfo')
}

/**
 * 获取用户提交的认证类型以及审核状态
 * @param params
 * @returns
 */
export function getUserAuthenticationType(): Res<any> {
  return service.get('/h5/user/getUserAuthenticationType')
}

/**
 * 意见反馈提交
 * @param data
 * @returns
 */
export function submitFeedback(data: {
  mobile: string
  text: string
  equipmentNumber: string
  androidVersion: string
  respondent: string
}): Res<any> {
  return service.post('/h5/feedback/submitFeedback', data)
}

/**
 *获取动态详情
 * @returns
 */
export function getUpdates(params: {
  updatesId: any
  userId: number
}): Res<any> {
  return service.get('/h5/updates/getUpdates', { params })
}

/**
 * 获取频道消息详情
 * @param params
 * @returns
 */
export function getChannelMsgDetail(params: { channelMsgId: any }): Res<any> {
  return service.get('/h5/channel/getChannelMsgDetail', { params })
}

/**
 * 频道消息点赞/取消点赞
 * @param data
 * @returns
 */
export function likeChannelMsg(data: {
  channelMsgId: any
  like: number
}): Res<any> {
  return service.post('/h5/channel/likeChannelMsg', data)
}

/**
 * 评论新增/回复
 * @param data
 * @returns
 */
export function insertComment(data: {
  channelMsgId: number
  content: string
}): Res<any> {
  return service.post('/h5/channel/insertComment', data)
}

/**
 * 评论列表
 * @param params
 * @returns
 */
export function pageComment(params: {
  channelMsgId: any
  pageNum: number
  pageSize: number
}): Res<any> {
  return service.get('/h5/channel/pageComment', { params })
}

/**
 * 评论点赞/取消点赞
 * @param data
 * @returns
 */
export function likeComment(data: { id: number; type: number }): Res<any> {
  return service.post('/h5/channel/likeComment', data)
}

/**
 * 评论删除
 * @param data
 * @returns
 */
export function deleteComment(data: { id: number }): Res<any> {
  return service.post('/h5/channel/deleteComment', data)
}

/**
 * 获取预览链接信息
 * @param params
 * @returns
 */
export function getPreviewChannelMsg(params: { msgId: any }): Res<any> {
  return service.get('/h5/channel/getPreviewChannelMsg', { params })
}

/**
 * 评论投诉
 * @param data
 * @returns
 */
export function commentComplaint(data: { id: number; type: any }): Res<any> {
  return service.post('/h5/channel/commentComplaint', data)
}

/**
 * 动态评论分页获取接口
 * @param params
 * @returns
 */
export function getpageComment(params: {
  updatesId: any
  pageNum: number
  pageSize: number
}): Res<any> {
  return service.get('/h5/updates/pageComment', { params })
}

/**
 * 金豆-app提现配置列表
 * @returns
 */
export function getAppWithdrawConfigList(): Res<any> {
  return service.get('/h5/goldenBeanWithdrawConfig/getAppWithdrawConfigList')
}

/**
 * 检查用户是否绑定微信
 * @returns
 */
export function checkUserBindWx(): Res<any> {
  return service.get('/h5/user/checkUserBindWx')
}

/**
 * 申请提现接口
 * @param data
 * @returns
 */
export function applyDraw(data: { exchangeId: number }): Res<any> {
  return service.post('/h5/gold/bean/apply/with/draw', data)
}

/**
 * 金豆获取清单
 * @returns
 */
export function getObtainDetailList(): Res<any> {
  return service.get('/h5/gold/bean/getObtainDetailList')
}

/**
 * 金豆兑换申请记录列表
 * @returns
 */
export function getExchangeDetailList(params: {
  pageNum: number
  pageSize: number
}): Res<any> {
  return service.get('/h5/gold/bean/getExchangeDetailList', { params })
}

/**
 * 申请提现到银行卡接口
 * @param data
 * @returns
 */
export function drawBankCard(data: {
  exchangeId: number | string
  payType: number
}): Res<any> {
  return service.post('/h5/gold/bean/apply/with/draw', data)
}

/**
 * 获取亲密度规则h5
 * @param params
 * @returns
 */
export function getIntimacyByH5(params: { toUid: string }): Res<any> {
  return service.get('/h5/getIntimacyByH5', { params })
}

/**
 * 获取用户绑定信息
 * @returns
 */
export function getBindInfo(): Res<any> {
  return service.get('/h5/bind/getBindInfo')
}

/**
 * 用户绑定
 * @param data
 * @returns
 */
export function saveBindInfo(data: any): Res<any> {
  return service.post('/h5/bind/saveBindInfo', data)
}

/**
 * 创建订单
 * @param data
 * @returns
 */
export function apiCreateOrder(data: any): Res<any> {
  return service.post('/h5/pay/createOrder', data)
}

/**
 * 支付成功回调
 * @param data
 * @returns
 */
export function apiPayNotify(data: any): Res<any> {
  return service.post('/h5/pay/notify', data)
}

/**
 * 获取paypal token
 * @param data
 * @returns
 */
export function apiGetAccessToken(): Res<any> {
  return service.get('/h5/pay/getAccessToken')
}

// 去paypal创建订单
export function apiPayPalCreateOrder(
  data: any,
  Authorization: string
): Res<any> {
  return Axios.post(`${paypalUrl}/v2/checkout/orders`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Authorization}`
    }
  })
}

// 去paypal捕获订单
export function apiCaptureErrorOrder(
  orderId: string,
  Authorization: string
): Res<any> {
  return Axios.post(`${paypalUrl}/v2/checkout/orders/${orderId}/capture`, '', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Authorization}`
    }
  })
}
/**
 * 获取钻石收支记录列表
 * @returns
 */
export function apiGetDiamondIncomes(type: number) {
  return service.get(`/h5/recharge/getDiamondRevenueExpensesList?type=${type}`)
}

/**
 * 获取充值规则列表
 * @returns
 */
export function apiGetDiamondRuleList() {
  return service.get('/h5/recharge/getRechargeRuleList')
}

/**
 * 上报支付失败原因
 * @returns
 */
export function apiMarkFailReason({ failReason, orderNo }: any) {
  return service.post(
    `/h5/pay/markFailReason?failReason=${failReason}&orderNo=${orderNo}`
  )
}
/**
 * 上报支付失败原因
 * @returns
 */
export function apiReportdiamondStatistics(type: any) {
  return service.post('/h5/diamondStatistics/report', `type=${type}`)
}

/**
 * 获取支付方式
 * @returns
 */
export function apiGetPayTypeConfig() {
  return service.post('/h5/countryPay/getConfig')
}

/**
 *
 * @param data 会员包创建订单
 * @returns
 */
export function apiCreateVipOrder(data: any): Res<any> {
  return service.post('/h5/pay/createVipOrder', data)
}

/**
 * 真人认证回调
 * @returns
 */
export function getCheckAuthentication(data: any): Res<any> {
  return service.post(
    `/h5/authentication/checkAuthenticationResult?biztoken=${data}`
  )
}

/**
 * 支付失败记录保存
 * @param data
 * @returns
 */
export function savePayFailedReason({
  type,
  reason,
  productId
}: any): Res<any> {
  return service.post(
    `/h5/payFailedReason/save?type=${type}&reason=${reason}&productId=${productId}`
  )
}

/**
 * 支付失败记录list
 * @returns
 */
export function getPayFailedReasonList(): Res<any> {
  return service.get(`/h5/payFailedReason/list`)
}
