<template>
  <div class="googlePay">
    <!-- 导航栏 -->
    <van-sticky>
      <van-nav-bar @click-left="handelBack">
        <template #title>
          <span>{{ $t('选择支付方式') }}</span>
        </template>
        <template #left>
          <img class="back" src="@/assets/icon_back_01.png" alt="" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="loading">
      <van-loading size="70" vertical
        ><div class="text">
          Bad internet connection, please wait a second...
        </div></van-loading
      >
    </div>

    <div class="content">
      <div class="pay_type">
        <div class="item_type" v-for="(item, index) in payConfig" :key="index">
          <template>
            <div class="type">
              <img :src="item.icon" alt="" />
              <span>{{ item.payName }}</span>
            </div>
            <img
              class="select"
              :src="
                payTypeId == item.id
                  ? require('@/assets/select_pay.png')
                  : require('@/assets/select_no.png')
              "
            />
          </template>
        </div>
      </div>
      <div class="pay_btn">
        <div class="btn" @click="paymentClick" ref="payBtn">
          {{ $t('立即续费') + ` ${payUnit + olderPrice}` }}
        </div>
        <div class="agreement">
          {{ $t('点击订阅后，我们将向你收取费用，点击即表示你同意我们的') }}
          <a
            :href="
              `https://tili-test-1253202267.cos.ap-chengdu.myqcloud.com/overseasSocial/user-agreement-${language}.html`
            "
            >{{ $t('用户协议') }}</a
          >、
          <a
            :href="
              `https://tili-test-1253202267.cos.ap-chengdu.myqcloud.com/overseasSocial/rechare-agreement-${language}.html`
            "
            >{{ $t('用户充值协议') }}</a
          >、
          <a
            :href="
              `https://tili-test-1253202267.cos.ap-chengdu.myqcloud.com/overseasSocial/privacy-agreement-${language}.html`
            "
            >{{ $t('隐私政策') }}</a
          >
        </div>
      </div>

      <!-- paypal支付选择 -->
      <van-popup
        v-model="isShowPaypal"
        position="bottom"
        class="prop"
        @close="hindPayProp()"
      >
        <div>
          <div id="payment-paypal" class="payment-paypal-btn"></div>
          <div id="payment-card" class="payment-paypal-btn"></div>
        </div>
        <div class="cancelPaypal" @click="hindPayProp()">
          <span>{{ $t('取消') }}</span>
        </div>
      </van-popup>
    </div>

    <div class="logo">
      <img src="@/assets/mail-img.png" alt="" />
    </div>
  </div>
</template>

<script>
import {
  appFunc_callExit,
  appFunc_senToken,
  appFunc_callGooglePayVip,
  appFunc_payFail
} from '@/utils/bridge'
import { CommonModule } from '@/store/modules/common'

import paypalPurchase from './mixins/paypalPurchase.js'
// import googlePurchase from './mixins/googlePurchase.js'
import payReport from './mixins/payReport.js'
import codaPurchase from './mixins/codaPurchase.js'

import { apiGetPayTypeConfig } from '@/api/index'

export default {
  mixins: [paypalPurchase, payReport, codaPurchase],
  data() {
    return {
      payTypeId: 0, // 当前支付方式的id
      countryId: 0, // coda 国家id
      olderPrice: 0, // 订单金额
      vipId: 0, // 会员包id
      isShowPaypal: false,
      isShowCodaPay: false,
      params: {},
      payConfig: [],
      language: 'en',
      payUnit: ''
    }
  },
  methods: {
    // 返回
    handelBack() {
      appFunc_callExit()
    },

    // timer = null

    // 谷歌支付
    // googlePay() {
    //   let timer = setTimeout(() => {
    //     clearTimeout(timer)
    //     timer = null
    //   }, 2000)
    //   this.reportPayClick('google')
    //   appFunc_callGooglePay({
    //     pageform: this.$route.query.pageform || '',
    //     ...this.ruleList[this.currentIndex]
    //   })
    //   // appFunc_RegisterGooglePaySuccess(this.googlePaySuccess)
    // },

    // 获取token
    async getToken() {
      const res = await appFunc_senToken()
      console.log(res)
      console.log(res.tokenForJs, '---------------')
      CommonModule.setUserId(res.id)
      CommonModule.setToken(res.tokenForJs)
      CommonModule.setUserPhone(res.mobile)
      CommonModule.setUserName(res.name)
    },

    // 支付
    async paymentClick() {
      switch (this.payConfig[0].payType) {
        case -1: // google支付
          appFunc_callGooglePayVip(this.params)
          break
        case -2: // paypal支付
          this.isShowPaypal = true
          await this.initPaypal(this.params)
          break
        case -3: // paypal支付
          this.isShowPaypal = true
          await this.initPaypal(this.params)
          break
        default:
          // coda支付
          this.isShowCodaPay = true
          await this.paymentCodaClick()
          break
      }
    },

    // coda支付
    async paymentCodaClick() {
      const item = this.payConfig[0]
      await this.clickCodaPay({ ...this.params, countryId: item.id })
    },

    // 取消Paypal支付
    hindPayProp() {
      this.isShowPaypal = false
      const eleChildPaypal = document.getElementById('payment-paypal')
      const eleChildCard = document.getElementById('payment-card')
      eleChildPaypal.innerHTML = ''
      eleChildCard.innerHTML = ''
    }
    // 轮询调用支付api，直到成功
  },

  async created() {
    const { id, price, countryId, language, unit, payId } = this.$route.query
    this.payTypeId = payId
    this.vipId = id
    this.countryId = countryId
    this.olderPrice = price
    this.language = language || 'en'
    this.payUnit = unit || '$'
    this.params = { countryId, vipId: id, olderPrice: price }
    await this.getToken()
    // }
    // Initialize Firebase
    this.initFirebase()
    // appFunc_RegisterFinishCb(this.googlePaySuccess)

    this.$nextTick(() => {
      console.log('执行')
      const query = this.$route.query
      console.log(query, 'this.$route.query---------')
      // 支付返回回调
      if (query.transactionId) {
        this.codaPayNotify(this.$route.query)
      }
      this.report('viprechargepage')
      // apiReportdiamondStatistics(this.$route.query.pageform == 1 ? 3 : 4)
    })
  },
  mounted() {
    // 直接调支付api
    apiGetPayTypeConfig().then(res => {
      if (res.code == 1001) {
        this.payConfig = res.data.filter(item => item.id == this.payTypeId)
        if (this.payConfig.length) {
          this.$refs.payBtn.click()
        }
      }
    })
    // 进入-金豆页
    // 挂载完成后，判断浏览器是否支持popstate--物理返回键
    if (window.history && window.history.pushState) {
      history.pushState(null, '', document.URL)
      window.addEventListener('popstate', this.handelBack, false)
    }
  },
  //页面销毁时，取消监听。否则其他vue路由页面也会被监听--物理返回键
  destroyed() {
    window.removeEventListener('popstate', this.handelBack, false)
  }
}
</script>

<style lang="scss">
.googlePay {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px;
  height: 100vh;
  overflow-y: scroll;
  visibility: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  box-sizing: border-box;
  padding-bottom: 100px;

  .pay_type {
    display: flex;
    flex-direction: column;

    .item_type {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // margin-bottom: 20px;
      .type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;

        img {
          width: 40px;
          margin-right: 20px;
        }
      }

      .select {
        width: 21px;
        height: 21px;
      }
    }
  }

  .pay_btn {
    height: 140px;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    width: 100vw;
    padding: 14px;
    box-sizing: border-box;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 347px;
      height: 48px;
      font-weight: bold;
      font-size: 18px;
      color: #181818;
      background: #f98c4f;
      border-radius: 16px 16px 16px 16px;
    }

    .agreement {
      height: 40px;
      font-size: 12px;
      color: #181818;
      line-height: 14px;
      margin-top: 12px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      a {
        font-weight: bold;
        font-size: 13px;
        font-size: 12px;
        color: #181818;
        text-decoration: underline;
      }
    }
  }
}

.prop {
  // height: 40vh;
  padding: 20px 0;
  border-radius: 30px 30px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .payment-paypal-btn {
    width: calc(100vw - 40px);
    height: 50px;
    margin: 10px 0;
    // background: #F98C4F;
  }

  .cancelPaypal {
    width: calc(100vw - 40px);
    height: 50px;
    margin-top: 30px;
    text-align: center;
    line-height: 50px;
    background: #f98c4f;
    font-weight: bold;
    font-size: 18px;
    color: #181818;
    border-radius: 16px 16px 16px 16px;
  }
}

.propCoda {
  height: 30%;
}

.payment-wrap {
  width: 100%;
  box-sizing: border-box;

  .payment-paypal-btn {
    margin-bottom: 10px;
  }

  .coda-payment-btn {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #f8e856;
    border-radius: 10px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: LexendDeca-Bold;
  }

  .icon-pay {
    width: 60px;
    height: 25px;
    margin-right: 8px;
  }

  .payment-google-btn {
    width: 347px;
    height: 44px;
    background: #f8e856;
    border-radius: 10px;
    @include bg-image('diamond/icon_goolgle@2x');
    background-size: 106px 20px;
    background-position: center;
    margin-bottom: 12px;
  }

  // google-pay-button {
  //   width: 100%;
  //   // margin-bottom: 10px;
  // }
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text {
  width: 100vw;
  text-align: center;
  font-size: 20px;
  padding: 0 20px;
  box-sizing: border-box;
  color: #666;
}
.logo {
  position: absolute;
  bottom: 50px;
}
</style>
