import {
  callAppFunc_jumpToLiveRoom,
  callAppFunc_openPersonalDataPopUp,
  callAppFunc_jumpToPersonalCenter
} from './bridge'

/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-08 14:18:50
 * @LastEditors: pansp
 * @LastEditTime: 2025-01-16 11:10:20
 */
export function dateFormat(fmt: string, date: Date) {
  const o: any = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}

export function useDebug() {
  if (
    window.location.href.includes('debug=true') ||
    window.navigator.userAgent.toLocaleLowerCase().includes('debug') ||
    window.TAKTAK_DEBUG
  ) {
    window.TAKTAK_DEBUG = true
  }
  if (window.location.origin.includes('test')) {
    window.TAKTAK_DEBUG = true
  }
  if (window.TAKTAK_DEBUG) {
    import(/* webpackChunkName: "vconsole" */ 'vconsole').then(value => {
      const vconsole = value.default
      new vconsole()
    })
  }

  return window.TAKTAK_DEBUG || false
}
export const _utf8_encode = function(string: string) {
  string = string.replace(/\r\n/g, '\n')
  let utftext = ''
  for (let n = 0; n < string.length; n++) {
    const c = string.charCodeAt(n)
    if (c < 128) {
      utftext += String.fromCharCode(c)
    } else if (c > 127 && c < 2048) {
      utftext += String.fromCharCode((c >> 6) | 192)
      utftext += String.fromCharCode((c & 63) | 128)
    } else {
      utftext += String.fromCharCode((c >> 12) | 224)
      utftext += String.fromCharCode(((c >> 6) & 63) | 128)
      utftext += String.fromCharCode((c & 63) | 128)
    }
  }
  return utftext
}
export const encode = function(input: string) {
  const _keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let output = ''
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4
  let i = 0
  input = _utf8_encode(input)
  while (i < input.length) {
    chr1 = input.charCodeAt(i++)
    chr2 = input.charCodeAt(i++)
    chr3 = input.charCodeAt(i++)
    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63
    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output =
      output +
      _keyStr.charAt(enc1) +
      _keyStr.charAt(enc2) +
      _keyStr.charAt(enc3) +
      _keyStr.charAt(enc4)
  }
  return output
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
export function getDeviceId() {
  let deviceId = localStorage.getItem('deviceId')
  if (!deviceId) {
    deviceId = guid()
    localStorage.setItem('deviceId', deviceId)
  }
  return deviceId
}

/**
 * - horizontal 横屏
 * - half 半屏
 * - vertical 竖屏
 */
export function getScreen() {
  const ua = navigator.userAgent.toLocaleLowerCase()
  if (ua.includes('horizontal')) {
    return 'horizontal'
  } else if (ua.includes('half')) {
    return 'half'
  } else {
    return 'vertical'
  }
}

export type Platform = 'weixin' | 'qq' | 'allo' | undefined
export function getPlatform(): Platform {
  const ua = navigator.userAgent
  if (/microMessenger/i.test(ua)) {
    return 'weixin'
  } else if (/qq\//i.test(ua)) {
    return 'qq'
  } else if (/allo/i.test(ua)) {
    return 'allo'
  } else {
    // return 'kixmix'
  }
}

export function toLiveRoom(
  uid: string | number,
  isLive: boolean | 0 | 1 = false
) {
  if (!uid) {
    return
  }
  if (isLive) {
    return callAppFunc_jumpToLiveRoom(uid)
  } else {
    if (getScreen() !== 'vertical') {
      return callAppFunc_openPersonalDataPopUp(uid)
    } else {
      return callAppFunc_jumpToPersonalCenter(uid)
    }
  }
}

export function getLanguage() {
  const language = navigator.language
  if (language.startsWith('fr')) {
    return 'fr'
  } else if (language.includes('ar')) {
    return 'ar'
  } else if (language.includes('tr')) {
    return 'turki'
  } else {
    // 比较语言代码是否为阿拉伯语
    return 'en'
  }
}

export const curLanguage = getLanguage()
export const curLanguageDirect = () => {
  const rightLanguages = ['ar']
  if (rightLanguages.includes(curLanguage)) {
    return 'language-right'
  }
  return 'language-left'
}

// 防抖
export const debounce = (fn: any, delay = 500) => {
  let timer: any = null
  return function(this: any, ...args: any) {
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
      timer = null
    }, delay)
  }
}


/**
 * 
 * @param v1 当前版本
 * @param v2 制定版本
 * @returns -1 当前版本小于指定版本 1 当前版本大于指定版本 0 当前版本等于指定版本
 */
export function compareVersions(v1: string, v2: string) {
  if (v1 == v2) {
    return 0
  }

  const vs1 = v1.split('.').map(a => parseInt(a))
  const vs2 = v2.split('.').map(a => parseInt(a))

  const length = Math.min(vs1.length, vs2.length)
  for (let i = 0; i < length; i++) {
    if (vs1[i] > vs2[i]) {
      return 1
    } else if (vs1[i] < vs2[i]) {
      return -1
    }
  }

  if (length == vs1.length) {
    return -1
  } else {
    return 1
  }
}